export interface DeviceRequest {
  itemNumbers: string[];
  productFamily: string;
  downloadGroups: string[];
  editGroups: string[];
  hideUploader: boolean;
  supportGroup?: string;
  sickEmployeeRead?: boolean;
}

export interface DeviceUpdateRequest extends DeviceRequest {
  deviceId: string;
}

export interface Device {
  deviceId: string;
  artifactIds: string[];
  itemNumbers: string[];
  productFamily: string;
  downloadGroups: string[];
  editGroups: string[];
  hideUploader: boolean;
  supportGroup?: string;
  sickEmployeeRead: boolean;
}
