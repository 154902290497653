import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { InternationalizationService } from '@art-repo/shared/services';
import { Logger } from '@art-repo/shared/utils';
import { ArtiEnvironment, ENVIRONMENT } from '@environments/model';
import { AuthConfig, OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class AppInitializerService {
  private logger = new Logger(this.constructor.name);

  constructor(
    private internationalizationService: InternationalizationService,
    private oauthService: OAuthService,
    private userService: UserService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    @Inject(ENVIRONMENT) private environment: ArtiEnvironment,
  ) {}

  public async initialize(): Promise<void> {
    this.internationalizationService.setup();
    await this.configureOidc();
    await this.userService.loadUserGroups();
  }

  private async configureOidc(): Promise<void> {
    // ... then setup oidc and try to login
    const redirectUri = `${this.document.location.protocol}//${this.document.location.host}${this.document.location.pathname}`;
    const oidcDefaults: AuthConfig = {
      redirectUri,
      responseType: 'code',
      scope: 'openid profile email sick-employee',

      silentRefreshTimeout: 20000,
      timeoutFactor: 0.9,
      postLogoutRedirectUri: this.document.location.origin,
      showDebugInformation: false,
    };

    this.oauthService.configure(Object.assign(oidcDefaults, this.environment.oidc));
    this.oauthService.events.subscribe((event) => {
      if (event instanceof OAuthErrorEvent) {
        this.logger.warn('Auth event:', event);
      }
    });
    this.oauthService.setupAutomaticSilentRefresh();
    await this.oauthService.loadDiscoveryDocument();

    // Case 1: User already has a valid idToken: just reloaded the site, we can immediately
    // use it and continue working
    if (this.oauthService.hasValidIdToken()) {
      this.logger.debug('Found valid existing idToken - skipping auth roundtrip.');
      return;
    }

    // No idToken, then try to consume a login redirect (might be there)
    await this.oauthService.tryLoginCodeFlow();

    // Case 2: It did work \o/, we're authenticated, check license and navigate to
    // target URL, if necessary.
    if (this.oauthService.hasValidAccessToken()) {
      this.logger.debug('Valid token after trying to login');
      const stateUrl: string | undefined = this.oauthService.state;

      // Check state: this can contain some query parameters - if so, navigate including them...
      if (stateUrl) {
        this.router.navigateByUrl(`${this.document.location.pathname}${decodeURIComponent(stateUrl)}`);
      }
      return;
    }

    // Case 3: Nope, didn't work - need to trigger the auth flow by calling initCodeFlow. Pass
    // the current location _including_ a potential query string as state for a subsequent
    // navigation *after* user has been logged in.
    this.logger.info('No usable credentials detected, sending user off to OIDC');

    this.oauthService.initCodeFlow(`${this.document.location.search}`);
    return;
  }
}
