import { SlicePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IconComponent, ItemDialogComponent, NoElementsPlaceholderComponent } from '@art-repo/shared/components';
import { AcceptDismissModalComponent } from '@art-repo/shared/components/modals';
import { Device, GroupNameMap } from '@art-repo/shared/models';
import { DeviceService, GroupManagementService, UserService } from '@art-repo/shared/services';
import { Logger } from '@art-repo/shared/utils';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ConfirmationService, Footer, MessageService, PrimeTemplate } from 'primeng/api';
import { ButtonDirective } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DialogService } from 'primeng/dynamicdialog';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { TableModule } from 'primeng/table';
import { Observable, switchMap } from 'rxjs';
import { AddDeviceDialogComponent } from '../add-device-dialog/add-device-dialog.component';

interface EnhancedDevice extends Device {
  isEditable: boolean;
}

@Component({
  selector: 'app-device-table',
  templateUrl: './device-table.component.html',
  styleUrls: ['./device-table.component.scss'],
  standalone: true,
  imports: [
    AcceptDismissModalComponent,
    DialogModule,
    Footer,
    ButtonDirective,
    ScrollPanelModule,
    TableModule,
    PrimeTemplate,
    NoElementsPlaceholderComponent,
    SlicePipe,
    TranslateModule,
    IconComponent,
  ],
})
export class DeviceTableComponent {
  @Input() public isAdmin = false;

  public searching = false;
  public showDeleteError = false;
  public devices: EnhancedDevice[] = [];
  public groupNames: GroupNameMap = {};

  private logger: Logger;
  private lastSearchString: string | undefined;

  constructor(
    private translateService: TranslateService,
    private dialogService: DialogService,
    private userService: UserService,
    private deviceService: DeviceService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private groupMgmtService: GroupManagementService,
  ) {
    this.logger = new Logger(this.constructor.name);
  }

  public loadDevices(searchString?: string): void {
    this.lastSearchString = searchString;
    let searchObservable: Observable<Device[]>;

    this.searching = true;

    if (typeof searchString === 'undefined') {
      searchObservable = this.deviceService.getAllDevices();
    } else {
      searchObservable = this.deviceService.searchDevice(searchString);
    }

    searchObservable
      .pipe(
        switchMap((devices) => {
          // set of groupIds in current devices, no duplicates
          const groupIds = new Set<string>();
          const temp: EnhancedDevice[] = [];
          for (const dev of devices) {
            temp.push({
              ...dev,
              isEditable: this.userService.isMemberOfGroupId(dev.editGroups) || this.isAdmin,
            });
            dev.downloadGroups.forEach((group) => groupIds.add(group));
            dev.editGroups.forEach((group) => groupIds.add(group));
          }
          this.devices = temp;
          return this.groupMgmtService.getGroupNames(groupIds);
        }),
      )
      .subscribe({
        next: (groupNames) => {
          this.groupNames = groupNames;
          this.searching = false;
        },
        error: (error) => {
          const errorText = `search string ${searchString}`;
          this.messageService.add({
            severity: 'error',
            summary: 'Search Error',
            detail: `Error during search for ${errorText}`,
          });
          this.logger.error(error, errorText);
          this.searching = false;
        },
      });
    return;
  }

  public reload(): void {
    this.loadDevices(this.lastSearchString);
  }

  public allItems(header: string, items: string[], groups = false) {
    let itemsToShow: string[] = [];
    if (groups) {
      items.forEach((item) => {
        itemsToShow.push(this.groupNames[item] || this.translateService.instant('deviceTable.unknownGroup'));
      });
    } else {
      itemsToShow = items;
    }

    this.dialogService.open(ItemDialogComponent, {
      data: {
        items: itemsToShow,
      },
      header: this.translateService.instant(header),
      style: {
        'max-width': '80%',
        'min-width': '30%',
        'min-height': '50%',
        'max-height': '80%',
      },
    });
  }

  public onDeleteClick(deviceId: string, artifactCount: number) {
    if (artifactCount > 0) {
      this.showDeleteError = true;
      return;
    }

    this.confirmationService.confirm({
      key: 'delete-device-modal',
      accept: () => {
        this.deviceService.deleteDevice(deviceId).subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success',
              summary: this.translateService.instant('deviceTable.delete.delete-device-summary'),
              detail: this.translateService.instant('deviceTable.delete.delete-device-detail'),
              life: 3000,
            });
            this.reload();
          },
          error: (error) => {
            this.messageService.add({
              severity: 'error',
              summary: this.translateService.instant('deviceTable.delete.delete-error-summary'),
              detail: error.error,
            });
            this.logger.error(error);
            this.searching = false;
          },
        });
      },
    });
  }

  public onEditClick(device: Device) {
    this.dialogService
      .open(AddDeviceDialogComponent, {
        header: this.translateService.instant('addDeviceDialog.editHeader'),
        data: {
          isEditMode: true,
          device,
        },
        style: {
          'min-width': '28%',
          'max-width': '28%',
          'max-height': '80%',
        },
        focusOnShow: false,
      })
      .onClose.subscribe({
        next: () => this.reload(),
      });
  }

  public asString(val: unknown): string {
    return val as string;
  }

  public hideDeleteError() {
    this.showDeleteError = false;
  }
}
