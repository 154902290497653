import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ArtiEnvironment, ENVIRONMENT } from '@environments/model';
import { Observable } from 'rxjs';
import { Device, DeviceRequest, DeviceUpdateRequest } from '../models';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  constructor(
    private httpClient: HttpClient,
    @Inject(ENVIRONMENT) private environment: ArtiEnvironment,
  ) {}

  public createDevice(
    itemNumbers: string[],
    productFamily: string,
    downloadGroups: string[],
    editGroups: string[],
    hideUploader: boolean,
    sickEmployeeRead: boolean,
    supportGroup?: string,
  ): Observable<Device> {
    const request: DeviceRequest = {
      itemNumbers,
      productFamily,
      downloadGroups,
      editGroups,
      hideUploader,
      supportGroup,
      sickEmployeeRead,
    };
    return this.httpClient.post<Device>(this.environment.apiUrl + 'device', request);
  }

  public updateDevice(
    deviceId: string,
    itemNumbers: string[],
    productFamily: string,
    downloadGroups: string[],
    editGroups: string[],
    hideUploader: boolean,
    sickEmployeeRead: boolean,
    supportGroup?: string,
  ): Observable<Device> {
    const request: DeviceUpdateRequest = {
      deviceId,
      itemNumbers,
      productFamily,
      downloadGroups,
      editGroups,
      hideUploader,
      supportGroup,
      sickEmployeeRead,
    };
    return this.httpClient.put<Device>(this.environment.apiUrl + 'device/' + deviceId, request);
  }

  public deleteDevice(deviceId: string): Observable<void> {
    return this.httpClient.delete<void>(this.environment.apiUrl + 'device/' + deviceId);
  }

  public searchDevice(searchString: string): Observable<Device[]> {
    return this.httpClient.get<Device[]>(this.environment.apiUrl + 'device/search', {
      params: {
        searchString,
      },
    });
  }

  public getDevice(deviceId: string): Observable<Device> {
    return this.httpClient.get<Device>(this.environment.apiUrl + 'device/' + deviceId);
  }

  public getAllDevices(): Observable<Device[]> {
    return this.httpClient.get<Device[]>(`${this.environment.apiUrl}device`);
  }
}
